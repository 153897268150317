import styled from '@emotion/styled'
import { Box, Button, Checkbox, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Formik, FieldArray, Form } from 'formik';
import { useGlobalContext } from '../utils/Context';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DeleteIcon from '../assets/svg/DeleteIcon';
import FileSaver from "file-saver";
import S3 from 'react-aws-s3';
import AWS from 'aws-sdk';
window.Buffer = window.Buffer || require("buffer").Buffer;

AWS.config.update({
  accessKeyId: "AKIAXDVUHDXYDTNQ5SS4",
  secretAccessKey: "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",
  region: "ap-south-1",
});
const AddChartNew = () => {
    const { setBarChartData, barChartData, customChartsData, setCustomChartsData } = useGlobalContext();
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const isCustom = location.search?.includes('custom');
    let findCurrentData = {};
    if(isCustom){
        findCurrentData = customChartsData?.find((data, ind) => ind == id)
    } else {
        findCurrentData = barChartData?.find((data, ind) => ind == id)
    }
    const FormWrapper = styled(Box)({
        display: 'flex',
        '.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {
            padding: '6.5px 14px'
        },
        '.sidebar': {
            // height: '100vh',
            height: 'auto',
            width: '10vw',
            backgroundColor: '#265AA8'
        },
        '.mb-30': {
            marginBottom: '30px'
        },
        '.custom-divider': {
            // width: '10px'
            borderBottomWidth: '10px',
            borderRadius: '5px'
        },
        '.w-25': {
            width: '25vw'
        },
        '.font-blue': {
            color: '#265AA8'
        },
        '.mr-10': {
            marginRight: '10px'
        },
        '.w-10': {
            width: '10vw',
            marginBottom: '10px'
        },
        '.inline-block': {
            display: 'inline-block'
        },
        '.title-font': {
            fontWeight: 'bold',
            fontSize: '18px',
            marginBottom: '15px'
        },
        '.sub-font': {
            fontSize: '16px',
            marginBottom: '10px'
        },
        '.flex': {
            display: 'flex',
            justifyContent: 'center'
        },
        '.form-section': {
            padding: '5vh 5vw',
            width: '90vw',
            height: '100vh',
            overflow: 'auto'
        },
        '.add-col-btn': {
            position: 'absolute',
            top: '-10px',
            marginLeft: '1.6rem',
        },
        '.pos-rel': {
            position: 'relative',
        },
        '.delete-btn': {
            fontSize: '25px',
            color: 'red',
            cursor: 'pointer'
        },
        '.form-container': {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        '.submit-btn': {
            marginTop: '5vh',
            height: '55px',
            width: '190px',
            backgroundColor: '#F19E38',
            borderRadius: '15px'
        },
        '.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root': {
            borderRadius: '10px',
            width: '8vw'
        },
        '.css-fvipm8': {
            borderRadius: '10px',
            width: '8vw'
        },
        '.upload-btn': {
            borderRadius: '10px',
            backgroundColor: '#777676',
            padding: '12px 16px',
            color: 'white',
            cursor: 'pointer',
            marginLeft: '10px',
        },
        '.bg-white': {
            backgroundColor: 'white',
            border: '1px solid #000000',
            color: 'black'
        },
        '.ml-1': {
            // marginLeft: '1.5vw'
            marginLeft: '2rem'
        },
        '.custom-checkbox': {
            padding: '0px'
        },
        '.title-wrapper': {
            display: 'flex',
            justifyContent: 'space-between'
        },
        '.inline-flex': {
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'start'
        },
        '.child-center': {
            alignSelf: 'center'
        },
        '.ml-10': {
            marginLeft: '10px'
        },
        '.flex-col': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }
    })
    const CustomTextField = styled(TextField)(() => ({
        '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
            // padding: '10px'
            padding: '7px'
        },
        '.css-1x5jdmq': {
            padding: '7px'
        },
        '.MuiInputBase-formControl': {
            borderRadius: '10px'
        }
    }));


    const user = localStorage.getItem('user');

    useEffect(() => {
        if (!user) {
            navigate('/login');
        }
    }, [user])
    return (
        <FormWrapper>
            <Box className='sidebar'></Box>

            <Box className='form-section'>
                <Formik
                    initialValues={!!findCurrentData ? findCurrentData : {
                        name: '',
                        legendPosition: 'bottom',
                        showXAxis: false,
                        chartData: {
                            header: { columns: ['', ''] },
                        },
                        currRow: 0,
                        showLegends: false,
                        xAxisTitle: '',
                        yAxisTitle: '',
                        rows: [{ vals: ['', ''] }]
                    }}
                    enableReinitialize={true}
                    onSubmit={(values) => {
                        if(isCustom){
                            setCustomChartsData((prev) => {
                                const updatedData = prev?.map((data, ind) => {
                                    if (id == ind) {
                                        delete values?.currRow;
                                        return values;
                                    }
                                    else {
                                        return data;
                                    }
                                })
                                return updatedData;
                            })
                            navigate('/chart/custom');
                        } else {

                            setBarChartData((prev) => {
                                const updatedData = prev?.map((data, ind) => {
                                    if (id == ind) {
                                        delete values?.currRow;
                                        return values;
                                    }
                                    else {
                                        return data;
                                    }
                                })
                                return updatedData;
                            })
                            navigate('/chart/bar');
                        }
                    }}
                >
                    {({ values, handleChange, setFieldValue, handleSubmit }) => (
                        <Form onSubmit={handleSubmit} className='form-container'>
                            <Box>
                                <Box className='mb-30'>
                                    <Grid container spacing={2}>
                                        <Grid item md={3} className='flex-col'>
                                            <Typography>
                                                Add Chart Title
                                            </Typography>
                                            <CustomTextField fullWidth onChange={handleChange} name='name' value={values.name} />
                                        </Grid>
                                        <Grid item md={3}>
                                            <Box className='title-wrapper'>
                                                <Typography>
                                                    X-axis title
                                                </Typography>
                                                <Box>
                                                    <Checkbox className='custom-checkbox' name='showXAxis' checked={values?.showXAxis} onChange={(e) => setFieldValue('showXAxis', e.target.checked)} /> Show Title On Graph?
                                                </Box>
                                            </Box>
                                            <CustomTextField fullWidth onChange={handleChange} name='xAxisTitle' value={values.xAxisTitle} />
                                        </Grid>
                                        <Grid item md={3} className='flex-col'>
                                            <Typography>
                                                Y-axis title
                                            </Typography>
                                            <CustomTextField fullWidth onChange={handleChange} name='yAxisTitle' value={values.yAxisTitle} />
                                        </Grid>
                                        <Grid item md={3}>
                                            <Typography>
                                                Select Legend Position
                                            </Typography>
                                            <FormControl size="small">
                                                <Select
                                                    name='legendPosition'
                                                    value={values.legendPosition || ''}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={'left'}>Left</MenuItem>
                                                    <MenuItem value={'right'}>Right</MenuItem>
                                                    <MenuItem value={'bottom'}>Bottom</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <Checkbox className='custom-checkbox ml-10' name='showLegends' checked={values?.showLegends} onChange={(e) => setFieldValue('showLegends', e.target.checked)} /> Show Legends On Graph?
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Typography className='title-font'>
                                    Chart Data
                                </Typography>
                                <Box>
                                    <FieldArray name='rows'>
                                        {({ push }) => (
                                            <>
                                                <div className='pos-rel'>
                                                    {
                                                        values?.chartData?.header?.columns?.map((p, ind) => {
                                                            return (
                                                                <React.Fragment key={`${ind}-cols`}>
                                                                    <Box key={ind} className='inline-block'>
                                                                        {ind > 1 && <Box className='sub-font flex'>
                                                                            <Tooltip title="delete column">
                                                                                <span className='delete-btn'
                                                                                    onClick={() => {
                                                                                        const filteredCols = values?.chartData?.header?.columns?.filter((col, colInd) => colInd !== ind);
                                                                                        setFieldValue('chartData.header.columns', filteredCols);
                                                                                        const filteredRows = values?.rows?.map((row, rowInd) => {
                                                                                            const filteredVals = row?.vals?.filter((currRow, innerInd) => innerInd !== ind);
                                                                                            return {
                                                                                                ...row,
                                                                                                vals: filteredVals
                                                                                            }
                                                                                        })
                                                                                        setFieldValue('rows', filteredRows);
                                                                                    }}
                                                                                >
                                                                                    <DeleteIcon />
                                                                                </span>
                                                                            </Tooltip>

                                                                        </Box>}
                                                                        <Box className='sub-font flex'>{ind === 0 ? 'Reference' : `Data Column ${ind + 1}`}</Box>
                                                                        <CustomTextField
                                                                            disabled={ind === 0}
                                                                            className='mr-10 w-10'
                                                                            onChange={handleChange}
                                                                            name={ind !== 0 ? `chartData.header.columns[${ind}]` : 'xAxisTitle'}
                                                                            value={ind !== 0 ? values?.chartData?.header?.columns?.[ind] : values?.xAxisTitle}
                                                                        />
                                                                    </Box>
                                                                </React.Fragment>
                                                            );
                                                        })
                                                    }
                                                    <Button variant="text" type='button' className='font-blue add-col-btn'
                                                        onClick={() => {
                                                            setFieldValue(`chartData.header.columns.${values.chartData.header.columns.length}`, '')
                                                            const updatedRows = values.rows?.map((row) => {
                                                                return {
                                                                    ...row,
                                                                    vals: row.vals?.concat('')
                                                                }
                                                            })
                                                            setFieldValue('rows', updatedRows);
                                                        }
                                                        }>
                                                        Add Column +
                                                    </Button>
                                                    {
                                                        <div>
                                                            {
                                                                values.rows?.map((data, ind) => {
                                                                    return (
                                                                        <div>

                                                                            {
                                                                                data.vals?.map((innerData, innerInd) => {
                                                                                    return (
                                                                                        <Box className='inline-flex'>
                                                                                            <CustomTextField
                                                                                                className='mr-10 w-10'
                                                                                                key={`row-${ind}-${innerInd}`}
                                                                                                name={`rows.${ind}.vals.${innerInd}`}
                                                                                                value={values?.rows?.[ind]?.vals?.[innerInd]}

                                                                                                onChange={(e) => {
                                                                                                    handleChange(e)
                                                                                                }}
                                                                                            />
                                                                                            {
                                                                                                data?.vals?.length - 1 == innerInd && ind > 0 &&
                                                                                                <Tooltip title="delete row" className='child-center'>
                                                                                                    <span className='delete-btn'
                                                                                                        onClick={() => {
                                                                                                            const filteredRows = values?.rows?.filter((row, rowInd) => rowInd !== ind);
                                                                                                            setFieldValue('rows', filteredRows)
                                                                                                        }}
                                                                                                    >
                                                                                                        <DeleteIcon />
                                                                                                    </span>
                                                                                                </Tooltip>
                                                                                            }
                                                                                            {
                                                                                                data?.vals?.length - 1 == innerInd &&
                                                                                                <>
                                                                                                    {
                                                                                                        <>
                                                                                                            <label id="file-input-label" onClick={(e) => {
                                                                                                                setFieldValue('currRow', ind);
                                                                                                            }} for="file-input" className={`upload-btn ${ind === 0 ? 'ml-1' : ''} ${values?.rows?.[ind]?.fileName ? 'bg-white' : ''}`}
                                                                                                            >
                                                                                                                {
                                                                                                                    values?.rows?.[ind]?.fileName ? values?.rows?.[ind]?.fileName : 'Upload File'
                                                                                                                }
                                                                                                            </label>

                                                                                                            <input type='file' id='file-input' onChange={(e) => {
                                                                                                                const file = e.target.files?.[0];
                                                                                                                const s3 = new AWS.S3();
                                                                                                                const params = {
                                                                                                                    Bucket: 'inovec',
                                                                                                                    Key: file?.name,
                                                                                                                    Body: file,
                                                                                                                    ContentDisposition: "inline",
                                                                                                                    ContentType: file?.type
                                                                                                                }
                                                                                                                s3.upload(params, (err, data) => {
                                                                                                                    if (err) {
                                                                                                                        console.error('Error===>', err);
                                                                                                                    } else {
                                                                                                                        setFieldValue(`rows.${values?.currRow}.inputType`, file?.name?.includes('pdf') ? 'pdf' : 'ppt');
                                                                                                                        setFieldValue(`rows.${values?.currRow}.fileName`, data?.Key);
                                                                                                                        setFieldValue(`rows.${values?.currRow}.url`, data?.Location);
                                                                                                                    }
                                                                                                                });
                                                                                                            }} accept="application/pdf,.ppt,.pptx" />
                                                                                                        </>
                                                                                                    }
                                                                                                </>
                                                                                            }
                                                                                        </Box>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>

                                                    }
                                                </div>
                                                <div>
                                                    <Button variant="text" type='button' className='font-blue'
                                                        onClick={() => {
                                                            const totalValues = values.rows?.[0]?.vals?.length;
                                                            const valLength = Array.from({ length: totalValues }, (breakPoint, ind) => '');
                                                            push({ vals: valLength })
                                                        }
                                                        }
                                                    >Add Row +</Button>
                                                </div>
                                            </>
                                        )}
                                    </FieldArray>
                                </Box>
                            </Box>
                            <Box>
                                <Divider className='custom-divider' />
                                <Button variant='contained' type='submit' className='submit-btn'>
                                    Update Chart
                                </Button>
                            </Box>
                        </Form>
                    )}
                </Formik>

            </Box>

        </FormWrapper>
    )
}

export default AddChartNew