import styled from '@emotion/styled';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Formik, FieldArray, Form } from 'formik';
import { useGlobalContext } from '../utils/Context';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DeleteIcon from '../assets/svg/DeleteIcon';
import FileSaver from 'file-saver';
import S3 from 'react-aws-s3';
import AWS from 'aws-sdk';
window.Buffer = window.Buffer || require('buffer').Buffer;

AWS.config.update({
  accessKeyId: "AKIAXDVUHDXYDTNQ5SS4",
  secretAccessKey: "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",
  region: "ap-south-1",
});
const AddPieChart = () => {
    const { pieChartData, setPieChartData, customChartsData, setCustomChartsData } =
        useGlobalContext();
    const { id } = useParams();
    const location = useLocation();
    const isCustom = location.search?.includes('custom');
    const navigate = useNavigate();
    const is4By3 = location.search?.includes('is4By3=true')
    let findCurrentData = {};
    if (isCustom) {
        findCurrentData = customChartsData?.find((data, ind) => ind == id);
    } else {
        findCurrentData = pieChartData?.find((data, ind) => ind == id);
    }
    const FormWrapper = styled(Box)({
        display: 'flex',
        '.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
            padding: '6.5px 14px',
        },
        '.sidebar': {
            // height: '100vh',
            height: 'auto',
            width: '10vw',
            backgroundColor: '#265AA8',
        },
        '.mb-30': {
            marginBottom: '30px',
        },
        '.custom-divider': {
            // width: '10px'
            borderBottomWidth: '10px',
            borderRadius: '5px',
        },
        '.w-25': {
            width: '25vw',
        },
        '.font-blue': {
            color: '#265AA8',
        },
        '.mr-10': {
            marginRight: '10px',
        },
        '.w-10': {
            width: '10vw',
            // marginBottom: '10px'
        },
        '.inline-block': {
            display: 'inline-block',
        },
        '.title-font': {
            fontWeight: 'bold',
            fontSize: '18px',
            marginBottom: '15px',
        },
        '.sub-font': {
            fontSize: '16px',
            marginBottom: '10px',
        },
        '.flex': {
            display: 'flex',
            justifyContent: 'center',
        },
        '.form-section': {
            padding: '5vh 5vw',
            width: '90vw',
            height: '100vh',
            overflow: 'auto',
        },
        '.add-col-btn': {
            position: 'absolute',
            top: '-10px',
            marginLeft: '1.6rem',
        },
        '.pos-rel': {
            position: 'relative',
        },
        '.delete-btn': {
            fontSize: '25px',
            color: 'red',
            cursor: 'pointer',
        },
        '.form-container': {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        '.submit-btn': {
            marginTop: '5vh',
            height: '55px',
            width: '190px',
            backgroundColor: '#F19E38',
            borderRadius: '15px',
        },
        '.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root': {
            borderRadius: '10px',
            width: '8vw',
        },
        '.css-fvipm8': {
            borderRadius: '10px',
            width: '8vw',
        },
        '.upload-btn': {
            borderRadius: '10px',
            backgroundColor: '#777676',
            padding: '12px 16px',
            color: 'white',
            cursor: 'pointer',
            marginLeft: '10px',
        },
        '.bg-white': {
            backgroundColor: 'white',
            border: '1px solid #000000',
            color: 'black',
        },
        '.ml-1': {
            // marginLeft: '1.5vw'
            marginLeft: '2rem',
        },
        '.custom-checkbox': {
            padding: '0px',
        },
        '.title-wrapper': {
            display: 'flex',
            justifyContent: 'space-between',
        },
        '.inline-flex': {
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'start',
        },
        '.child-center': {
            alignSelf: 'center',
        },
        '.flex-col': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        '.chart-head': {
            display: 'flex',
            gap: '11%',
            width: '100%',
        },
        '.justify-start': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginBottom: '7px',
            // border: '2px solid red'
        },
        '.flex-input': {
            display: 'flex',
            gap: '20px',
        },
        '.w_22': {
            width: '22%',
        },
        '.w_fit_content': {
            width: 'fit-content',
        },
        '.w-40': {
            width: '40%',
        },
    });
    const CustomTextField = styled(TextField)(() => ({
        '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
            // padding: '10px'
            padding: '7px',
        },
        '.css-1x5jdmq': {
            padding: '7px',
        },
        '.MuiInputBase-formControl': {
            borderRadius: '10px',
        },
    }));

    const user = localStorage.getItem('user');

    useEffect(() => {
        if (!user) {
            navigate('/login');
        }
    }, [user]);
    return (
        <FormWrapper>
            <Box className='sidebar'></Box>

            <Box className='form-section'>
                <Formik
                    initialValues={
                        !!findCurrentData
                            ? {...findCurrentData, showLegends: (findCurrentData?.showLegends !== false && parseInt(id) === 0) ? true : findCurrentData?.showLegends}
                            : {
                                  name: '',
                                  currRow: 0,
                                  series: [{ data: [{ name: '', y: '' }] }],
                                  legendPosition: 'right',
                                  showTitle: false,
                                  showLegends: false,
                                  legendFontSize: 6,
                                  dataLabelFontSize: 10,
                              }
                    }
                    enableReinitialize={true}
                    onSubmit={(values) => {
                        if (isCustom) {
                            setCustomChartsData((prev) => {
                                const updatedData = prev?.map((data, ind) => {
                                    if (id == ind) {
                                        delete values?.currRow;
                                        return values;
                                    } else {
                                        return data;
                                    }
                                });
                                return updatedData;
                            });
                            navigate('/chart/custom');
                        } else {
                            setPieChartData((prev) => {
                                const updatedData = prev?.map((data, ind) => {
                                    if (id == ind) {
                                        delete values?.currRow;
                                        return values;
                                    } else {
                                        return data;
                                    }
                                });
                                return updatedData;
                            });
                            navigate(`/chart/pie${is4By3 ? '4-3' : '3-3'}`);
                        }
                    }}
                >
                    {({ values, handleChange, setFieldValue, handleSubmit }) => (
                        <Form onSubmit={handleSubmit} className='form-container'>
                            <Box>
                                <Box className='mb-30'>
                                    <Grid container spacing={2}>
                                        {/* <Grid item md={3} className='flex-col'>
                                            <Typography>
                                                Add Chart Title
                                            </Typography>
                                            <CustomTextField fullWidth onChange={handleChange} name='name' value={values.name} />
                                        </Grid> */}
                                        <Grid item md={12}>
                                            <Box className='title-wrapper'>
                                                <Typography>Add Chart Title</Typography>
                                            </Box>
                                            <Box className='flex-input'>
                                                <div className='w_22'>
                                                    <CustomTextField
                                                        fullWidth
                                                        onChange={handleChange}
                                                        name='name'
                                                        value={values.name}
                                                    />
                                                </div>
                                                <Box>
                                                    <Checkbox
                                                        className='custom-checkbox'
                                                        name='showTitle'
                                                        checked={values?.showTitle}
                                                        onChange={(e) =>
                                                            setFieldValue(
                                                                'showTitle',
                                                                e.target.checked
                                                            )
                                                        }
                                                    />{' '}
                                                    Show Title On Graph?
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item md={12} className='flex-input'>
                                            <Typography>Select Legend Position</Typography>
                                            <FormControl size='small'>
                                                <Select
                                                    className='w_fit_content'
                                                    name='legendPosition'
                                                    value={values.legendPosition || 'right'}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={'left'}>Left</MenuItem>
                                                    <MenuItem value={'right'}>Right</MenuItem>
                                                    <MenuItem value={'bottom'}>Bottom</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <Box>
                                                <Checkbox
                                                    className='custom-checkbox'
                                                    name='showLegends'
                                                    checked={values?.showLegends}
                                                    onChange={(e) =>
                                                        setFieldValue(
                                                            'showLegends',
                                                            e.target.checked
                                                        )
                                                    }
                                                />{' '}
                                                Show Legends On Graph?
                                            </Box>
                                        </Grid>
                                        <Grid item md={12} className='flex-input'>
                                            <Box>
                                                <Box className='title-wrapper'>
                                                    <Typography>Legend FontSize</Typography>
                                                </Box>
                                                <div>
                                                    <CustomTextField
                                                        className='w-40'
                                                        onChange={handleChange}
                                                        name='legendFontSize'
                                                        type='number'
                                                        placeholder={6}
                                                        value={values.legendFontSize}
                                                    />
                                                </div>
                                            </Box>
                                            <Box>
                                                <Box className='title-wrapper'>
                                                    <Typography>Datalabels FontSize</Typography>
                                                </Box>
                                                <Box>
                                                    <CustomTextField
                                                        className='w-40'
                                                        onChange={handleChange}
                                                        name='dataLabelFontSize'
                                                        type='number'
                                                        placeholder={10}
                                                        value={values.dataLabelFontSize}
                                                    />
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Typography className='title-font'>Chart Data</Typography>
                                <Box>
                                    <FieldArray
                                        name='series.0.data'
                                        render={({ insert, remove, push }) => (
                                            <div>
                                                {values?.series?.[0]?.data.map(
                                                    (currentItem, index) => (
                                                        <div key={index}>
                                                            {index === 0 && (
                                                                <div className='chart-head'>
                                                                    <span>Label</span>
                                                                    <span>Value</span>
                                                                </div>
                                                            )}
                                                            <div className='justify-start'>
                                                                <CustomTextField
                                                                    className='mr-10 w-10'
                                                                    // key={`${index}`}
                                                                    name={`series.0.data.${index}.name`}
                                                                    value={currentItem?.name}
                                                                    onChange={(e) => {
                                                                        handleChange(e);
                                                                    }}
                                                                />
                                                                <CustomTextField
                                                                    className='mr-10 w-10'
                                                                    // key={`${index}`}
                                                                    type={'number'}
                                                                    name={`series.0.data.${index}.y`}
                                                                    value={currentItem?.y}
                                                                    onChange={(e) => {
                                                                        handleChange(e);
                                                                    }}
                                                                />
                                                                {index > 0 && (
                                                                    <Tooltip
                                                                        title='delete row'
                                                                        className='child-center'
                                                                    >
                                                                        <span
                                                                            className='delete-btn'
                                                                            onClick={() => {
                                                                                remove(index);
                                                                            }}
                                                                        >
                                                                            <DeleteIcon />
                                                                        </span>
                                                                    </Tooltip>
                                                                )}
                                                                {
                                                                    <>
                                                                        {
                                                                            <>
                                                                                <label
                                                                                    id='file-input-label'
                                                                                    onClick={(
                                                                                        e
                                                                                    ) => {
                                                                                        setFieldValue(
                                                                                            'currRow',
                                                                                            index
                                                                                        );
                                                                                    }}
                                                                                    htmlFor='file-input'
                                                                                    className={`upload-btn ${
                                                                                        index === 0
                                                                                            ? 'ml-1'
                                                                                            : ''
                                                                                    } ${
                                                                                        values
                                                                                            ?.series?.[0]
                                                                                            ?.data?.[
                                                                                            index
                                                                                        ]?.fileName
                                                                                            ? 'bg-white'
                                                                                            : ''
                                                                                    }`}
                                                                                >
                                                                                    {values
                                                                                        ?.series?.[0]
                                                                                        ?.data?.[
                                                                                        index
                                                                                    ]?.fileName
                                                                                        ? values
                                                                                              ?.series?.[0]
                                                                                              ?.data?.[
                                                                                              index
                                                                                          ]
                                                                                              ?.fileName
                                                                                        : 'Upload File'}
                                                                                </label>

                                                                                <input
                                                                                    type='file'
                                                                                    id='file-input'
                                                                                    onChange={(
                                                                                        e
                                                                                    ) => {
                                                                                        const file =
                                                                                            e.target
                                                                                                .files?.[0];
                                                                                        const s3 =
                                                                                            new AWS.S3();
                                                                                        const params =
                                                                                            {
                                                                                                Bucket: 'inovec',
                                                                                                Key: file?.name,
                                                                                                Body: file,
                                                                                                ContentDisposition:
                                                                                                    'inline',
                                                                                                ContentType:
                                                                                                    file?.type,
                                                                                            };
                                                                                        s3.upload(
                                                                                            params,
                                                                                            (
                                                                                                err,
                                                                                                data
                                                                                            ) => {
                                                                                                if (
                                                                                                    err
                                                                                                ) {
                                                                                                    console.error(
                                                                                                        'Error===>',
                                                                                                        err
                                                                                                    );
                                                                                                } else {
                                                                                                    setFieldValue(
                                                                                                        `series.0.data.${values?.currRow}.inputType`,
                                                                                                        file?.name?.includes(
                                                                                                            'pdf'
                                                                                                        )
                                                                                                            ? 'pdf'
                                                                                                            : 'ppt'
                                                                                                    );
                                                                                                    setFieldValue(
                                                                                                        `series.0.data.${values?.currRow}.fileName`,
                                                                                                        data?.Key
                                                                                                    );
                                                                                                    setFieldValue(
                                                                                                        `series.0.data.${values?.currRow}.url`,
                                                                                                        data?.Location
                                                                                                    );
                                                                                                }
                                                                                            }
                                                                                        );
                                                                                    }}
                                                                                    accept='application/pdf,.ppt,.pptx'
                                                                                />
                                                                            </>
                                                                        }
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                                <div>
                                                    <Button
                                                        variant='text'
                                                        type='button'
                                                        className='font-blue'
                                                        onClick={() => {
                                                            push({ name: '' });
                                                        }}
                                                    >
                                                        Add Row +
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                    />
                                </Box>
                            </Box>
                            <Box>
                                <Divider className='custom-divider' />
                                <Button variant='contained' type='submit' className='submit-btn'>
                                    Update Chart
                                </Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </FormWrapper>
    );
};

export default AddPieChart;
